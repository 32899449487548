import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useContext } from 'react';
import { useBackgroundClass } from '../../styles/jss/utils';
import Link from '../Link/Link';
import useStyles from './ContentReference.styles';
import { SiteContext } from '../../utils/common';
import { useLoaded } from '../MainContent/useComponentLoading';
import SplitContentBySlash from '../../utils/SplitContentBySlash';
const ContentReference = (props) => {
    var _a, _b;
    const { heading, message, links, backgroundColor, textAlignment = 'LEFT', titleTextVariant = 'STANDARD', backgroundAndHeaderTextColors, } = props; // To make sure we handle any nulls from graphQL
    // Remove spaces from Amplience
    const { isEnabledGoldCTA } = props;
    const { isFlyingFlowers } = useContext(SiteContext);
    const [largeBackgroundColor, headingColor] = ((_a = backgroundAndHeaderTextColors === null || backgroundAndHeaderTextColors === void 0 ? void 0 : backgroundAndHeaderTextColors.split('-')) !== null && _a !== void 0 ? _a : []).map((color) => color.trim());
    const isNewBrandChange = Boolean(isEnabledGoldCTA !== null && isEnabledGoldCTA !== void 0 ? isEnabledGoldCTA : backgroundAndHeaderTextColors);
    const linksCount = (_b = links === null || links === void 0 ? void 0 : links.length) !== null && _b !== void 0 ? _b : 0;
    const { classes } = useStyles({
        linksCount,
        headingColor: headingColor,
        textAlignment: textAlignment !== null && textAlignment !== void 0 ? textAlignment : 'LEFT',
    });
    function determineBgColor() {
        if (isFlyingFlowers && backgroundColor)
            return backgroundColor;
        if (titleTextVariant && largeBackgroundColor)
            return largeBackgroundColor;
        return backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : 'WHITE'; // Use default if undefined
    }
    let bgColor = determineBgColor();
    const { bgClass } = useBackgroundClass(bgColor);
    const resolveHeadingTitle = (heading, isFlyingFlowers, headingLineClass) => {
        return !isFlyingFlowers ? (_jsx(SplitContentBySlash, { content: heading, classNames: { headingLine: headingLineClass } })) : (heading);
    };
    const formatMessage = !isFlyingFlowers ? (_jsx(SplitContentBySlash, { content: props.message, classNames: { headingLine: classes.headingLine } })) : (props.message);
    const renderButtonVariant = () => {
        if (isFlyingFlowers)
            return 'secondary';
        if (isEnabledGoldCTA)
            return 'primary';
        return 'tertiary'; // Default
    };
    const alignmentMapping = {
        LEFT: classes.textLeft,
        CENTER: classes.textCenter,
    };
    const textAlignmentClass = isFlyingFlowers
        ? classes.textCenter
        : alignmentMapping[textAlignment !== null && textAlignment !== void 0 ? textAlignment : ''] || classes.textLeft;
    const isLargeAdvertising = titleTextVariant === 'LARGE_ADVERTISING' && !isFlyingFlowers;
    const headingTitle = resolveHeadingTitle(props.heading, isFlyingFlowers, classes.headingLine);
    useLoaded(props);
    return (_jsx("div", { className: clsx(classes.container, bgClass), "data-testid": "content-reference", children: _jsx("div", { className: clsx(classes.content, textAlignmentClass), children: _jsxs("div", { className: classes.contentInner, children: [heading && (_jsx("h3", { className: clsx(classes.heading, !isFlyingFlowers && (isLargeAdvertising ? classes.largeTitle : classes.standardTitle), isLargeAdvertising && headingColor && classes.headingColor), children: headingTitle })), message && _jsx("div", { className: classes.message, children: formatMessage }), !!(links === null || links === void 0 ? void 0 : links.length) && (_jsx("div", { className: clsx(classes.container, classes.container, isFlyingFlowers && classes.linkContainerInline, !isFlyingFlowers &&
                            (isNewBrandChange ? classes.brandLinkContainer : classes.nonBrandLinksContentContainer)), children: links.map((link, index) => (_jsx(Link, Object.assign({}, link, { button: true,
                            variant: renderButtonVariant(),
                            thin: true,
                            className: classes.link,
                            icon: isFlyingFlowers ? 'sentiment_very_satisfied' : undefined }), `${link.label}_${index}`))) }))] }) }) }));
};
export default ContentReference;
