import { makeStyles } from 'tss-react/mui';
import { spacing, colors } from '../../styles/jss/variables';
const generateCommonLinkStyles = (theme, isFlyingFlowers, width) => ({
    width: isFlyingFlowers ? '50%' : width,
    display: 'table-cell',
    margin: 0,
    '& button': { width: '100%' },
});
const getAlignment = (textAlignment) => (textAlignment === 'CENTER' ? 'center' : 'flex-start');
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    content: {
        display: 'flex',
        position: 'relative',
        alignSelf: 'center',
        padding: theme.isFlyingFlowers ? spacing.large : spacing.medium,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            padding: spacing.large,
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.isFlyingFlowers ? spacing.xxl : spacing.xl,
        },
    },
    contentInner: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            alignSelf: 'center',
        },
    },
    heading: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : undefined }, (theme.isFlyingFlowers ? theme.typography.xl_regular : theme.typography.medium_bold)), (theme.isFlyingFlowers ? theme.typographySpacing.xl_regular_sm : theme.typographySpacing.medium_bold_lg)), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (!theme.isFlyingFlowers && theme.typography.xxl)), (theme.isFlyingFlowers ? theme.typographySpacing.xl_regular_lg : theme.typographySpacing.xxl_sm)) }),
    message: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : undefined }, theme.typography.small_regular), (theme.isFlyingFlowers ? theme.typographySpacing.small_regular_lg : theme.typographySpacing.small_regular_sm)), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_regular : theme.typography.small_regular)), (theme.isFlyingFlowers ? theme.typographySpacing.medium_regular_lg : theme.typographySpacing.small_regular_sm)) }),
    link: {
        width: '100%',
        display: 'flex',
        marginBottom: spacing.small,
        '&:last-child': {
            marginBottom: 0,
        },
        '& button': {
            flexGrow: 1,
        },
    },
    linkContainerInline: Object.assign({ [theme.breakpoints.up('md')]: {
            display: 'inline',
            paddingBottom: spacing.small,
            [`& .${classes.link}`]: Object.assign(Object.assign({}, generateCommonLinkStyles(theme, theme.isFlyingFlowers, '432.5px')), { '&:first-child': {
                    paddingRight: spacing.small,
                    paddingBottom: 0,
                } }),
        } }, (theme.isFlyingFlowers && {
        '& a': {
            whiteSpace: 'pre-wrap',
        },
    })),
    nonBrandLinksContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: getAlignment(_params.textAlignment),
        gap: spacing.small,
        [`& .${classes.link}`]: Object.assign({}, generateCommonLinkStyles(theme, theme.isFlyingFlowers, '100%')),
        [theme.breakpoints.up('sm')]: {
            [`& .${classes.link}`]: Object.assign({}, generateCommonLinkStyles(theme, theme.isFlyingFlowers, '335px')),
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: getAlignment(_params.textAlignment),
            gap: spacing.small,
            alignSelf: 'stretch',
            flexDirection: 'row',
        },
        [theme.breakpoints.up('lg')]: {
            [`& .${classes.link}`]: {
                minWidth: '400px',
            },
        },
        [theme.breakpoints.up('xl')]: {
            [`& .${classes.link}`]: {
                minWidth: '432.5px',
            },
        },
    },
    headingLine: {
        alignSelf: 'flex-start',
        marginBottom: spacing.xs,
        display: 'block',
    },
    largeTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_sm), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.xxl_bold), theme.typographySpacing.xxl_bold_sm) }),
    standardTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm), { [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm) }),
    headingColor: {
        color: (_params === null || _params === void 0 ? void 0 : _params.headingColor) && `${colors.genericContentHeading[_params === null || _params === void 0 ? void 0 : _params.headingColor]}`,
    },
    textLeft: {
        textAlign: 'left',
    },
    textCenter: {
        textAlign: 'center',
    },
    brandLinkContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: getAlignment(_params.textAlignment),
        justifyContent: getAlignment(_params.textAlignment),
        gap: spacing.small,
        [`& .${classes.link}`]: Object.assign({}, generateCommonLinkStyles(theme, theme.isFlyingFlowers, '100%')),
        [theme.breakpoints.up('sm')]: {
            [`& .${classes.link}`]: Object.assign({}, generateCommonLinkStyles(theme, theme.isFlyingFlowers, '335px')),
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'flex-start',
            gap: spacing.medium,
            alignSelf: 'stretch',
            flexDirection: 'row',
            [`& .${classes.link}`]: Object.assign({}, generateCommonLinkStyles(theme, theme.isFlyingFlowers, '400px')),
        },
        [theme.breakpoints.up('xl')]: {
            [`& .${classes.link}`]: Object.assign({}, generateCommonLinkStyles(theme, theme.isFlyingFlowers, '432.5px')),
        },
    },
}));
export default useStyles;
